import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class InactivityServiceService {
  private timeout: any;
  private readonly INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 1 h in milliseconds
  private _inactivityTimeout$ = new Subject<void>();

  constructor(
    private router: Router,
    private cookie: CookieService
  ) { }

  startTimer() {
    // Reset the timer whenever activity occurs
    this.resetTimer();

    // Listen for events to reset the timer
    window.addEventListener('click', () => this.resetTimer());
    window.addEventListener('mousemove', () => this.resetTimer());
    window.addEventListener('keydown', () => this.resetTimer());
  }

  resetTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.logout();
    }, this.INACTIVITY_TIMEOUT);
  }

  logout() {
    // Handle logout, e.g., clear cookies and local storage
    localStorage.clear();
    this.cookie.deleteAll();
    this.router.navigate(['/home']);
    this._inactivityTimeout$.next();
  }

  get inactivityTimeout$(): Observable<void> {
    return this._inactivityTimeout$.asObservable();
  }
}
