import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environement';
import { User } from '../models/user';
import { isPlatformBrowser } from '@angular/common';
import { InactivityServiceService } from './inactivity-service.service';

@Injectable({
  providedIn: 'root'
})
export class LoginServerService {
  BaseUrl = environment.apiUrl;
  isloggedIn: boolean = false;
  public userInfo?: any;
  private _refreshNeeded$ = new Subject<void>();

  constructor(
    private _http: HttpClient,
    private cookie: CookieService,
    private router: Router,
    private inactivityServiceService: InactivityServiceService,  // Inject InactivityService
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  get refreshNeeded$() {
    return this._refreshNeeded$.asObservable();
  }

  Authenticate(userEmail: string, userPassword: string): Observable<any> {
    return this._http.post(`${this.BaseUrl}/auth/signin`, { userEmail, userPassword }).pipe(
      tap((res: any) => {
        if (res.accessToken) {
          // Store user information in localStorage
          localStorage.setItem('user', userEmail);
          localStorage.setItem('userId', res.userId);
          localStorage.setItem('name', res.name);
          localStorage.setItem('userPhoneNumber', res.userPhoneNumber);
          localStorage.setItem('presence', res.accessToken);
          localStorage.setItem('_sid', res.roles[0]);

          // Start inactivity timer after successful login
          if (isPlatformBrowser(this.platformId) && this.isAuthenticate()) {
            this.inactivityServiceService.startTimer();  // Start the inactivity timer
          }
        } else {
          console.error("Access token not found in the response.");
        }
      })
    );
  }

  storeToken(token: string, role: string) {
    localStorage.setItem('presence', token);
    localStorage.setItem('_sid', role);
  }

  saveAccountUser(user: any) {
    const { token } = this.getSession();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const requestOptions = { headers: headers };

    return this._http.post(
      `${this.BaseUrl}/auth/signup`,
      user,
      requestOptions
    ).pipe(
      tap(() => {
        this._refreshNeeded$.next();
      })
    );
  }

  getSession() {
    return {
      token: localStorage.getItem('presence')?.toString(),
      role: localStorage.getItem('_sid')?.toString(),
    };
  }

  isTokenExpired() {
    const { token } = this.getSession();
    if (token) {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      return expiry * 1000 <= Date.now();
    }
    return false;
  }

  isAuthenticate(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('presence') !== null && localStorage.getItem('_sid') !== null;
    }
    return false;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/home']);
  }

  generateResetPasswordToken(userEmail: string): Observable<any> {
    return this._http.post<any>(`${this.BaseUrl}/auth/generate/${userEmail}`, null);
  }

  resetPassword(userData: any): Observable<boolean> {
    return this._http.post<boolean>(`${this.BaseUrl}/auth/reset-password`, userData);
  }

  currentUser(): Observable<User> {
    const email = JSON.parse(localStorage.getItem('user1') || '{}');
    return this._http.get<User>(`${this.BaseUrl}/email?email=${email}`);
  }
}
